<template>
  <v-navigation-drawer
    v-model="Sidebar_drawer"
    :dark="SidebarColor !== 'white'"
    :color="SidebarColor"
    mobile-break-point="960"
    clipped
    mini-variant-width="70"
    :expand-on-hover="expandOnHover"
    app
    id="main-sidebar"
    v-bar
  >
    <v-list dense expand nav class="mt-1">
      <template v-for="(item, i) in items">
        <!---If Sidebar Caption -->
        <v-row
          v-if="item.header && isShowHeader(item)"
          :key="item.header"
          align="center"
        >
          <v-col cols="12">
            <v-subheader v-if="item.header" class="d-block text-truncate">
              {{ item.header }}</v-subheader
            >
          </v-col>
        </v-row>
        <!---If Sidebar Caption -->
        <BaseItemGroup
          v-else-if="item.children"
          :key="`group-${i}`"
          :item="item"
        ></BaseItemGroup>

        <BaseItem v-else :key="`item-${i}`" :item="item" />
      </template>
      <!---Sidebar Items -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapState } from "vuex";
import menuItems from "../../../menu";
export default {
  name: "Sidebar",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },

  methods: {
    isShowHeader(item) {
      let action_count = 0;
      for (let action of item.gate.action) {
        if (this.$can("show", action)) {
          action_count++;
        }
      }
      if (action_count <= 0) {
        return false;
      }
      return true;
    },
  },
  computed: {
    ...mapState(["SidebarColor", "SidebarBg"]),
    items() {
      return menuItems;
    },
    Sidebar_drawer: {
      get() {
        return this.$store.getters.SidebarDrawer;
      },
      set(val) {
        this.$store.dispatch("setSidebar", val);
      },
    },
  },
  watch: {
    "$vuetify.breakpoint.mdAndDown"(val) {
      this.$emit("update:expandOnHover", !val);
    },
  },
};
</script>
<style lang="scss">
#main-sidebar {
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
  .v-navigation-drawer__border {
    display: none;
  }
  .v-list {
    // padding: 8px 15px;
  }
  .v-list-item {
    &__icon--text,
    &__icon:first-child {
      justify-content: center;
      text-align: center;
      width: 20px;
    }
  }
  .profile-bg {
    background: #888888;
    .v-avatar {
      padding: 45px 0;
    }
  }
}
</style>